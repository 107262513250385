import { Controller } from "@hotwired/stimulus"

export default class extends Controller<HTMLFormElement> {
  static targets: string[] = ["form", "input", "loadingCircle", "uploadButton"]

  declare readonly formTarget: HTMLFormElement
  declare readonly inputTarget: HTMLInputElement
  declare readonly loadingCircleTarget: HTMLElement
  declare readonly uploadButtonTarget: HTMLElement

  submit() {
    if (this.inputTarget.files?.length) {
      this.formTarget.requestSubmit()
      this.loadingCircleTarget.classList.remove("hidden")
      this.uploadButtonTarget.classList.add("hidden")
    }
  }
}
