import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from "stimulus-use"

export default class extends Controller<HTMLFormElement> {
  static targets = ["options"]

  declare readonly optionsTarget: HTMLElement

  connect() {
    useClickOutside(this)
  }

  toggle(event: Event) {
    event.preventDefault()
    this.optionsTarget.classList.toggle("hidden")
  }

  clickOutside() {
    if (!this.optionsTarget.classList.contains("hidden")) {
      this.optionsTarget.classList.add("hidden")
    }
  }
}
