import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
    let e = this.element;

    /*generated with Input range slider CSS style generator (version 20211225)
    https://toughengineer.github.io/demo/slider-styler*/
    e.style.setProperty('--value', e.value);
    e.style.setProperty('--min', e.min == '' ? '0' : e.min);
    e.style.setProperty('--max', e.max == '' ? '100' : e.max);
    e.addEventListener('input', () => e.style.setProperty('--value', e.value));
  }
}
