import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    copy: String
  }
  async copy() {
    if (!this.copyValue) return

    try {
      await navigator.clipboard.writeText(this.copyValue)
    } catch (err) {
      console.error(err.name, err.message)
    }
  }
}
