import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["count"]
  static values = {
    duration: { type: Number, default: 300 }
  }

  initialize() {
    this.start()
  }

  start() {
    const end = parseInt(this.countTarget.innerHTML.replace(/,/g, ''))
    const start = 0
    let startTimestamp = null
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp
      const progress = Math.min((timestamp - startTimestamp) / this.durationValue, 1)
      const currentCount = Math.floor(progress * (end - start) + start)
      this.countTarget.innerHTML = currentCount.toLocaleString()
      if (progress < 1) {
        window.requestAnimationFrame(step)
      }
    }
    window.requestAnimationFrame(step)
  }
}
